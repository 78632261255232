<template>
  <div class="filters">
    <div class="input-wrapper">
      <SearchIcon />
      <input v-model="search" type="text" class="search" placeholder="Поиск по разделу" />
    </div>
    <v-select
      v-model="type"
      variant="outlined"
      :items="templatesOptions"
      placeholder="Все статусы"
      item-title="name"
      item-value="name"
      class="select"
      clearable
    />
    <v-checkbox v-model="hasActivePoints" class="checkbox" label="Есть задачи" />
    <v-checkbox v-model="hasUserActivePoints" class="checkbox" label="Я участвую" />
  </div>
  <v-row v-if="isLoading" class="my-12 py-12 w-100 justify-center align-center">
    <v-progress-circular indeterminate color="primary" :size="50" class="ml-4" :width="3" />
  </v-row>
  <div v-else class="list">
    <template v-if="!isGroupingEnabled">
      <ProjectRow v-for="template in filteredTemplates" :key="template.id" :template="template" />
    </template>
    <template v-if="isGroupingEnabled">
      <template v-for="templateOrGroup in groupedTemplates">
        <ProjectRow v-if="!templateOrGroup.group" :template="templateOrGroup" />
        <ProjectGroup
          v-if="templateOrGroup.group"
          :group="templateOrGroup.group"
          :templates="templateOrGroup.templates"
        />
      </template>
    </template>
    <div
      v-if="!templates.length"
      class="text-caption text-grey text-center w-100 py-6 flex-grow-1 d-flex justify-center align-center"
    >
      Нет данных
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watch, computed } from 'vue'
import { api } from '@/api/Api'
import store from '@/store'
import SearchIcon from '@/components/Icons/SearchIcon.vue'
import ProjectRow from '@/components/Project/ProjectRow.vue'
import ProjectGroup from '@/components/Project/ProjectGroup.vue'
import { useDisplay } from 'vuetify'

const { sm, xs } = useDisplay()

const isLoading = ref(false)
const groups = ref([])
const templates = ref([])
const isGroupingEnabled = ref(true)
const search = ref('')
const hasActivePoints = ref(false)
const hasUserActivePoints = ref(false)
const type = ref()
const templatesOptions = ref([
  {
    id: 1,
    name: 'Прием заявок',
  },
])

async function getGroups() {
  try {
    isLoading.value = true

    await api
      .programGroups()
      .getGroups()
      .then((data) => (groups.value = data))
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

async function getTemplates() {
  try {
    isLoading.value = true

    await api
      .programs()
      .getAllActiveTemplates()
      .then((data) => (templates.value = data))
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

const setPageTitle = () => {
  if (xs.value || sm.value) {
    return store.commit('app/setPageTitle', 'Конкурсы')
  }
  return store.commit('app/setPageTitle', 'Текущие конкурсы')
}

watch([search, type, hasActivePoints, hasUserActivePoints], () => {
  isGroupingEnabled.value = !(
    search.value ||
    type.value ||
    hasActivePoints.value ||
    hasUserActivePoints.value
  )
})

const groupedTemplates = computed(() => {
  const seenGroups = new Set()

  return templates.value.reduce((result, template) => {
    if (template.groupCode && !seenGroups.has(template.groupCode)) {
      seenGroups.add(template.groupCode)

      result.push({
        group: groups.value.find((g) => g.code1C === template.groupCode) || {},
        templates: templates.value.filter((t) => t.groupCode === template.groupCode),
      })
    } else if (!template.groupCode) {
      result.push(template)
    }
    return result
  }, [])
})

const filteredTemplates = computed(() => {
  return templates.value.filter((template) => {
    const matchesSearch = template.name.toLowerCase().includes(search.value.toLowerCase())
    const matchesStatus = !type.value || template.status === type.value
    const matchesActivePoints = hasActivePoints.value ? template.hasActivePoints : true
    const matchesUserActivePoints = hasUserActivePoints.value ? template.hasUserActivePoints : true

    return matchesSearch && matchesStatus && matchesActivePoints && matchesUserActivePoints
  })
})

onMounted(() => {
  setPageTitle()
  getGroups()
  getTemplates()
})
</script>
<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  padding: 24px;
}

.filters {
  padding: 12px 24px;
  border-bottom: 1px solid rgba(242, 242, 242, 1);

  .checkbox {
    margin-bottom: 0;
    max-width: 136px;

    .v-label {
      font-size: 14px;
    }
  }
}
</style>

<style lang="scss">
.filters {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 24px;

  @media (max-width: 765px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  @media (max-width: 567px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .input-wrapper {
    background: var(--bage-color);
    border: 1px solid var(--bage-color);
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px;
    height: 40px;
    border-radius: 8px;
    flex: 1;

    input {
      height: 100%;
      min-height: 40px;
      display: block;
      flex: 1;
      font-size: 14px;
    }
  }

  .v-input--density-default {
    --v-input-control-height: 40px;
    --v-input-padding-top: 12px;
  }

  .v-input__details {
    display: none;
  }

  .checkbox {
    .v-icon--size-default {
      font-size: 24px;
    }
  }

  .select {
    max-width: 194px;
    width: 100%;

    @media (max-width: 765px) {
      max-width: 100%;
    }

    .v-field {
      border-radius: 8px;
    }
    .v-field__input {
      padding: 10px 12px;
      overflow: hidden;

      span {
        font-size: 14px;
        line-height: 20px;
        white-space: pre;
      }
    }
  }
}
</style>
