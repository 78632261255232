<template>
  <div :class="['ma-0 pa-0 list__item', paddingClass]">
    <div>
      <div class="card-title">{{ template.name }}</div>
      <RouterLink :to="`/programs/${template.id || template.code1C}`"
        >Подробнее <ArrowRight
      /></RouterLink>
    </div>
    <div>
      <v-row class="pa-0 ma-0 justify-space-between align-center task-row">
        <div class="chips">
          <v-tooltip v-if="template.status" :text="template.status" location="bottom">
            <template #activator="{ props }">
              <div v-bind="props" class="chip chip_black">
                {{ template.status }}
              </div>
            </template>
          </v-tooltip>

          <div v-if="template.hasUserActivePoints" class="chip chip_border">Участвую</div>
        </div>
        <div v-if="template.hasActivePoints" class="task">Есть задачи!</div>
      </v-row>
      <v-col class="pa-0 ma-0 flex-grow-1 font-weight-bold"> </v-col>
      <v-row class="pa-0 ma-0 d-flex align-end">
        <v-row class="pa-0 mx-0 flex-grow-1 w-100 dates flex-nowrap">
          <div v-if="template.dateFrom && template.dateTo">
            <div class="label">Проведение конкурса:</div>
            <div class="value">
              {{ getFormatDate(template.dateFrom) }} - {{ getFormatDate(template.dateTo) }}
            </div>
          </div>
          <div v-if="template.resultDateFrom && template.resultDateTo">
            <div class="label">Подведение итогов:</div>
            <div class="value">
              {{ getFormatDate(template.resultDateFrom) }} -
              {{ getFormatDate(template.resultDateTo) }}
            </div>
          </div>
        </v-row>
      </v-row>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import moment from 'moment'
import ArrowRight from '@/components/Icons/ArrowRight.vue'

const props = defineProps({
  template: {
    type: Object,
    required: true,
  },
  isInGroup: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const paddingClass = computed(() => (props.isInGroup ? 'pl-7' : ''))

function getFormatDate(date) {
  return moment(date).format('DD.MM.YYYY')
}
</script>


<style scoped lang="scss">
.card-title {
  a {
    svg {
      color: var(--primary);
    }
  }
}

.list__item {
  background: var(--bage-color);
  display: grid;
  grid-template-columns: 1fr 380px;
  align-items: stretch;
  border-radius: 8px;

  a {
    font-size: 12px;
    line-height: 16px;
  }

  @media (max-width: 765px) {
    grid-template-columns: 1fr;
  }

  > div {
    padding: 24px;
    display: flex;
    flex-direction: column;

    &:first-child {
      border-right: 2px solid white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;

      @media (max-width: 765px) {
        padding-bottom: 0;
      }
    }

    a {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .dates {
      gap: 16px;
      margin-top: 16px;

      @media (max-width: 765px) {
        flex-direction: column;
        gap: 8px;
      }

      .label {
        color: var(--text-caption);
        font-size: 10px;
      }

      .value {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
</style>
