<template>
  <div class="group">
    <div class="card-title pa-6 cursor-pointer" @click="toggleGroup">
      <v-icon :icon="groupExpandIcon" />
      {{ group.name }} ({{ templates.length }} шт.)
      <div v-if="!isGroupExpanded" class="more pt-6">Подробнее <ArrowRight class="ml-1" /></div>
    </div>
    <v-expand-transition>
      <div v-if="isGroupExpanded" class="expand-content">
        <div v-for="template in templates" :key="template.code1C">
          <v-divider />
          <ProjectRow :template="template" is-in-group />
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import ProjectRow from '@/components/Project/ProjectRow.vue'
import ArrowRight from '@/components/Icons/ArrowRight.vue'

defineProps({
  group: {
    type: Object,
    required: true,
  },
  templates: {
    type: Array,
    required: true,
  },
})

const isGroupExpanded = ref(false)

const toggleGroup = () => {
  isGroupExpanded.value = !isGroupExpanded.value
}

const groupExpandIcon = computed(() =>
  isGroupExpanded.value ? 'mdi-chevron-down' : 'mdi-chevron-right',
)
</script>

<style scoped lang="scss">
div.more {
  font-size: 12px;
  font-weight: normal;
  color: var(--primary);
}

.group {
  border-radius: 8px;
  background: var(--bage-color);
}
</style>
